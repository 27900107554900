import React, { FC, useState } from "react";

import { ITextField } from "common/types";

import { EditIcon, SaveIcon } from "@global/iconComponents";
import { P } from "@global/Texts";

const TextField: FC<ITextField> = ({ label, value, placeholder, handleEdit, editable = false }) => {
	const [editing, setEditing] = useState(false);
	const [inputValue, setInputValue] = useState(undefined);

	return (
		<div className='flex flex-col space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0'>
			<P className="text-grey">{label}</P>
			{!editing && <div className="flex space-x-2">
				<p className='text-white'> {value || placeholder} </p>
				<a onClick={() => setEditing(true)}
					className='
						stroke-yellow-dark
						cursor-pointer
						hover:stroke-yellow-lighter
					'
				>
					{editable && EditIcon({ width: '24px', heigh: '24px', stroke: 'current' })}
				</a>
			</div>
			}
			{editing &&
				<div className="flex flex-row items-center gap-x-4">
					<input
						className="mokens-input-sm"
						value={inputValue}
						onChange={(e) => {
							setInputValue(e.target.value);
						}}
					/>
					<a
						onClick={async () => {
							await handleEdit(inputValue);
							setEditing(false);
						}}
						className='
							cursor-pointer
							fill-yellow-dark
							hover:fill-yellow-lighter
						'
					>
						{SaveIcon({ width: '24px', height: '24px', fill: 'current', stroke: 'current' })}
					</a>
				</div>
			}
		</div>
	)
}

export default TextField;